.main-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #20419a;
    margin: 0;
    padding: 0;
}

.header {
    width: 100vw;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #20419a;
}

.logo {
    width: 10vw;
}

@media only screen and (max-width: 600px) {
    .header {
        width: 100vw;
        height: 15vh;


    }

    .logo {
        width: 40vw;
    }
}